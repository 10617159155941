import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addCompany,
    deleteCompany, getAllCompany, getSiteNotPage, updateCompany,
} from "../../../api";
import {getAreaPath, showFail, showNeedCompleteInfo, showSuccess, transform} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import PickLocationModal from "../../../components/pickLocationModal";
// @ts-ignore

const Company = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [sites, setSites] = useState<any[]>([])
        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetCleanCompanyList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            if (searchCondition?.companyName) {
                params.companyName = searchCondition?.companyName
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllCompany(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCleanCompanyList(pageNum, pageSize);
            getSiteNotPage({}).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    let _sites=[]
                    for (let i = 0; i <res.data.length ; i++) {
                        _sites.push({label: res.data[i].siteName , value : res.data[i].siteId})
                    }
                  setSites(_sites)
                    console.log(_sites)
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }, [pageSize, pageNum])

         const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            companyName: "",

        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }
    const [hydrantLng, setHydrantLng] = useState<string | number>();
    const [hydrantLat, setHydrantLat] = useState();
        const columns: any = [
            {
                title: '单位名称',
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: '单位类型',
                dataIndex: 'companyType',
                    render: (_: any, record: any) => (
                        <>
                            {(record?.companyType===0) ? (
                                <div>
                                    {'建筑垃圾'}
                                </div>
                            ) : (
                                <div>
                                    {'在建项目'}
                                </div>
                            )}
                        </>
                    )
            },
            {
                title: '单位联系人',
                dataIndex: 'companyContactName',
            },

            {
                title: '联系电话',
                dataIndex: 'companyContactMobile',
            },


            // {
            //     title: '处置点位',
            //     dataIndex: 'location',
            //     key: 'location',
            //     render: (_: any, record: any) => (
            //         <>
            //             {(record?.siteLat && record?.siteLng) ? (
            //                 <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
            //                      onClick={() => {
            //                          setLocationModalOpen(true);
            //                          setHydrantLat(record?.siteLat);
            //                          setHydrantLng(record?.siteLng);
            //                      }}/>
            //             ) : (<div>
            //                 <Tooltip title={'暂无定位'}>
            //                     <img src={noneIcon} alt="" width={20} height={20}/>
            //                 </Tooltip>
            //             </div>)}
            //         </>
            //     )
            // },
            // {
            //     title: '处置单位',
            //     dataIndex: ['garbageSite','siteName'],
            // },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                        <Popconfirm title={"是否确认删除该公司"} onConfirm={() => {
                            deleteCompany({companyId: record?.companyId}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetCleanCompanyList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>

                        <Button  type={'link'} color={'primary'}
                            onClick={()=>{
                                setUpdateModalVisible(true);
                                form.setFieldValue('companyName',record.companyName)
                                form.setFieldValue('companyContactName',record.companyContactName)
                                form.setFieldValue('companyContactMobile',record.companyContactMobile)
                                form.setFieldValue('companyType',record.companyType+'')
                                form.setFieldValue('siteId',record.siteId)
                                form.setFieldValue('companyId',record.companyId)
                            }}
                        >修改</Button>
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }

    const handleUpdateNewCompany = () => {
        form.validateFields().then((value) => {
            let newCompany = {
                companyName:value?.companyName,
                companyType:value?.companyType,
                companyContactName:value?.companyContactName,
                companyContactMobile:value?.companyContactMobile,
                companyId:form.getFieldValue('companyId'),
                site_lng: value?.location?.lng,
                site_lat: value?.location?.lat,
            }
            setLoading(true);
            updateCompany(newCompany).then((res: any) => {
                setLoading(false);
                setLoc(null);
                form.resetFields();
                console.log(res)
                if (res?.code === 200) {
                    handleGetCleanCompanyList(pageNum, pageSize).then(() => {
                        showSuccess(res?.msg);
                        handleCloseUpdateModal();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
        setUpdateModalVisible(true);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }
    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});
    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    //新增宿舍中的选择位置对话框
    const confirmName = () => {}


        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({companyName: e.target.value})}} placeholder={'公司名称'} allowClear></Input>

                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCleanCompanyList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            setAddModalVisible(true);
                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                <AddRoadModal open={addModalVisible} onClose={handleCloseAddModal} siteOptions={sites}
                                 handleGetHydrant={async () => {
                                     await handleGetCleanCompanyList(pageNum, pageSize, newSearchCondition)
                                 }}/>
                <Modal open={updateModalVisible} onCancel={() => {
                            handleCloseUpdateModal();
                            setLoading(false)
                        }} title={'清运公司修改'} maskClosable={false} okText={'确认提交'}
                               onOk={debounce(handleUpdateNewCompany, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                            <Form form={form} name="form" labelAlign="right" labelCol={{
                                xs: {span: 24},
                                sm: {span: 6},
                            }} wrapperCol={{
                                xs: {span: 24},
                                sm: {span: 14},
                            }}>
                                <Form.Item
                                    name="companyName"
                                    label="公司名称"
                                    rules={[
                                        {
                                            required: true, message: "请输入公司名称"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入公司名称"/>
                                </Form.Item>
                                <Form.Item
                                    name="companyType"
                                    label="单位类型"
                                    rules={[
                                        {
                                            required: true, message: "请选择单位类型"
                                        },
                                    ]}
                                >
                                    <Select
                                        options={[{label:'建筑垃圾',value:'0'},{label:'在建项目',value:'1'}]}
                                    >
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="companyContactName"
                                    label="单位联系人"
                                    rules={[
                                        {
                                            required: true, message: "请输入单位联系人"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入单位联系人"/>
                                </Form.Item>
                                <Form.Item
                                    name="companyContactMobile"
                                    label="联系电话"
                                    rules={[
                                        {
                                            required: true, message: "请输入联系电话"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系电话"/>
                                </Form.Item>
                            </Form>
                </Modal>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

                <PickLocationModal  loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                       setUpdateModalVisible(true);
                                   }} confirmAddress={() => {
                }} confirmRoad={() => {
                }} confirmName={confirmName} repick={true}/>

            </div>
        );
    }
;

export default Company;