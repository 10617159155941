import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCompany, addHydrant, addRoad, getSiteNotPage} from "../../api";
import PickUser from "../pickUser";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const [sites, setSites] = useState<any[]>([])
    useEffect(() => {
        getSiteNotPage({}).then((res: any) => {
            if (res?.code === 200) {
                setSites(res.data)
                console.log(res.data)
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, []);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddHydrantModal = ({
                             open,
                             onClose,
                             siteOptions,
                             handleGetHydrant
                         }: { open: boolean, onClose: () => void, siteOptions: any, handleGetHydrant: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewCompany = () => {
        form.validateFields().then((value) => {
            let newCompany = {
                companyName:value?.companyName,
                companyType:value?.companyType,
                companyContactName:value?.companyContactName,
                companyContactMobile:value?.companyContactMobile,
                site_lng: value?.location?.lng,
                site_lat: value?.location?.lat,
            }
            setLoading(true);
            addCompany(newCompany).then((res: any) => {
                setLoading(false);
                form.resetFields();
                setLoc(null);
                console.log(res)
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }
    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});
    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }
    const confirmName = () => {}


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();

                setLoading(false)
            }} title={'清运公司新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewCompany, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="companyName"
                        label="公司名称"
                        rules={[
                            {
                                required: true, message: "请输入公司名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入公司名称"/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name="location"*/}
                    {/*    label="处置点位"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true, message: "请选择定位"*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    {loc ? (<div style={{display: "flex", flexDirection: "row"}}>*/}
                    {/*            <div style={{color: "#235c7a"}}>*/}
                    {/*                <div>经度:{loc?.lng}</div>*/}
                    {/*                <div>纬度:{loc?.lat}</div>*/}
                    {/*            </div>*/}
                    {/*            <div style={{width: "20px"}}/>*/}
                    {/*            <div style={{display: "grid", placeItems: "center"}}>*/}
                    {/*                <Button type="primary" onClick={() => {*/}
                    {/*                    setPickLocModalOpen(true)*/}
                    {/*                }}>重新选择*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <Button type="primary" onClick={() => {*/}
                    {/*            getLocation();*/}
                    {/*            setPickLocModalOpen(true);*/}
                    {/*        }}>选择定位*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        name="companyType"
                        label="单位类型"
                        rules={[
                            {
                                required: true, message: "请选择单位类型"
                            },
                        ]}
                    >
                        <Select
                                options={[{label:'建筑垃圾',value:'0'},{label:'在建项目',value:'1'}]}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="companyContactName"
                        label="单位联系人"
                        rules={[
                            {
                                required: true, message: "请输入单位联系人"
                            },
                        ]}
                    >
                        <Input placeholder="请输入单位联系人"/>
                    </Form.Item>
                    <Form.Item
                        name="companyContactMobile"
                        label="联系电话"
                        rules={[
                            {
                                required: true, message: "请输入联系电话"
                            },
                        ]}
                    >
                        <Input placeholder="请输入联系电话"/>
                    </Form.Item>
                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={() => {}} confirmRoad={() => {}}
                                   confirmName={confirmName}
                                   repick={undefined}/>

            </Modal>

        </div>
    );
};

export default AddHydrantModal;