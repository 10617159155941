import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, Divider, Space
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addDemand,
    deleteDemand, getAllCompanyNotPage, getAllDemand, getDictData, updateDemand,
} from "../../../api";
import {
    getAreaPath,
    showFail,
    showNeedCompleteInfo,
    showSuccess,
    showWarning,
    transform
} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import {log} from "node:util";
import {Map, Marker} from "react-amap";
// @ts-ignore

const Demand = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [locationModalOpen, setLocationModalOpen] = useState(false);

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        let params: any = {}
        const handleGetCleanDemandList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            console.log( window.localStorage.getItem('companyId') )
            if ( window.localStorage.getItem('companyId') !== 'undefined') {
                params.companyId = window.localStorage.getItem('companyId')
            }
            if(searchCondition?.demanderPhone!=null){
                params.demanderPhone=searchCondition.demanderPhone
            }
            if(searchCondition?.companyId!=null){
                params.companyId=searchCondition.companyId
            }
            if(searchCondition?.demandType!=null){
                params.demandType=searchCondition.demandType
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllDemand(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCleanDemandList(pageNum, pageSize);
        }, [pageSize, pageNum])
        useEffect(() => {
            getDictData({model:'submit',attribute:'type'}).then((res:any) => {
                if (res?.code===200){
                    let demands=[]
                    for (let i = 0; i < res.data.length; i++) {
                        demands.push({label: res.data[i].description,value:res.data[i].description})
                    }
                    setDemandTypes(demands)
                }
            })
        }, [])
        const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            companyId: "",
            demanderPhone: "",
            demandType: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
    const [codeUrl, setCodeUrl] = useState<any>();
    const [imgModalVisible, setImgModalVisible] = useState<boolean>(false);
    const [ZjData,setZjData]=useState<any>()
    const handleCloseImgModal = () => {
        setImgModalVisible(false);
    }
        const columns: any = [
            {
                title: '作业单位',
                dataIndex: ['cleanCompany','companyName'],
            },
            {
                title: '需求类型',
                dataIndex: 'demandType',
                key: 'demandType',
            },
            {
                title: '需求状态',
                dataIndex: 'demandState',
                key: 'demandState',
            },
            {
                title: '组织名称',
                dataIndex: 'orgName',
                key: 'orgName',
            },
            {
                title: '需求发起照片',
                dataIndex: 'cleanDemandPictures',
                key: 'cleanDemandPictures',
                render: (_: any, record: any) => (

                    <div>
                        {record.cleanDemandPictures?.find((picture:any) => {
                            // 如果 pictureType 等于 1，则返回 true，否则返回 false
                            return picture.pictureType === 1;
                        })? <Button type={'link'} color={'primary'}
                                onClick={() => {
                                    let url=''
                                    for (let i = 0; i < record.cleanDemandPictures.length; i++) {
                                        if(record.cleanDemandPictures[i].pictureType==1){
                                            url=record.cleanDemandPictures[i].url
                                        }

                                    }
                                             setCodeUrl(url)
                                            setImgModalVisible(true)
                                    }
                                }

                        >查看</Button>:''}
                    </div>
                )
            },
            {
                title: '营业执照',
                dataIndex: 'cleanDemandPictures2',
                key: 'cleanDemandPictures2',
                render: (_: any, record: any) => (
                    <div>
                        {record.cleanDemandPictures?.find((picture:any) => {
                            // 如果 pictureType 等于 1，则返回 true，否则返回 false
                            return picture.pictureType === 2;
                        })? <Button type={'link'} color={'primary'}
                                                             onClick={() => {
                                                                 let url=''
                                                                 for (let i = 0; i < record.cleanDemandPictures.length; i++) {
                                                                     if(record.cleanDemandPictures[i].pictureType==2){
                                                                         url=record.cleanDemandPictures[i].url
                                                                     }

                                                                 }
                                                                 setCodeUrl(url)
                                                                 setImgModalVisible(true)
                                                             }
                                                             }

                        >查看</Button>:''}
                    </div>
                )
            },
            {
                title: '组织代码',
                dataIndex: 'orgCode',
                key: 'orgCode',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: '清运车牌',
                dataIndex: 'carLicense',
                key: 'carLicense',
            },
            {
                title: '清运联系电话',
                dataIndex: 'carPhone',
                key: 'carPhone',
            },
            {
                title: '司机姓名',
                dataIndex: 'driverName',
                key: 'driverName',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '需求定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.demandLat && record?.demandLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.demandLat);
                                     setHydrantLng(record?.demandLng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                        {record.demandType=='在建项目'?
                        <Button  type={'link'} color={'primary'}
                        onClick={()=>{
                            setUpdateModalVisible(true);
                            form.setFieldValue('demandName',record.demandName)
                            form.setFieldValue('demandId',record.demandId)
                            setZjData(record)
                        }}
                        >在建项目详情</Button>:''}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
        const handleCloseUpdateModal = () => {
            setUpdateModalVisible(false);
        }

        const handleUpdateNewDemand = () => {
            form.validateFields().then((value) => {
                let newDemand = {
                    demandName:value?.demandName,
                    demandId:form.getFieldValue('demandId')
                }
                setLoading(true);
                updateDemand(newDemand).then((res: any) => {
                    setLoading(false);
                    console.log(res)
                    if (res?.code === 200) {
                        handleGetCleanDemandList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseUpdateModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }

        const [companyList, setCompanyList] = useState<any[]>([]);

        const changeName = (e: any) => {
            let v = e?.target?.value;
            if (v) {
                let params: any = {companyName: v};

                getAllCompanyNotPage(params).then((res: any) => {
                    console.log(res)
                    if (res?.code === 200) {
                        setCompanyList(res?.data?.map((d: any) => {
                            return {
                                value: d?.companyId,
                                label: d.companyName
                            }
                        }))
                    }
                })
            } else {
                showWarning("请输入归属人姓名或手机号")
            }
        }

        const [demandTypes,setDemandTypes]=useState<any[]>()


        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    {/*<Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({demanderPhone: e.target.value,companyId: searchCondition.companyId})}} placeholder={'作业人手机号'} allowClear></Input>*/}
                    { window.localStorage.getItem('companyId') == 'undefined'?
                        <Select
                            style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                            allowClear={true}
                            placeholder={"请选择归属单位"}
                            onChange={(e)=>{
                                console.log(e)
                                setSearchCondition({demanderPhone: searchCondition.demanderPhone,companyId: e,demandType: searchCondition.demandType})}
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入单位名称"
                                            onChange={debounce(changeName, 600)}
                                        />
                                    </Space>
                                </>
                            )}
                            options={companyList}
                        />:''}
                    <Select
                        style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                        allowClear={true}
                        placeholder={"需求类型"}
                        onChange={(e)=>{
                            console.log(e)
                            setSearchCondition({demandType: e,demanderPhone: searchCondition.demanderPhone,companyId: searchCondition.companyId})}
                        }
                        options={demandTypes}
                    />
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCleanDemandList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>



                <Modal open={updateModalVisible} onCancel={() => {
                    handleCloseUpdateModal();
                    setLoading(false)
                }} title={'在建项目详情'} maskClosable={false}
                    footer={<div>
                                <Button  onClick={
                                    ()=>{handleCloseUpdateModal();
                                    setLoading(false)}}>取消</Button>
                            </div>}
                       okButtonProps={{loading: loading}}
                       destroyOnClose>
                    <Form form={form} name="form" labelAlign="right" >
                        <Form.Item name="orgName" label="项目名称">
                            <div>{ZjData?.orgName}</div>
                        </Form.Item>
                        <Form.Item name="projectType" label="项目类型">
                            <div>{ZjData?.projectType}</div>
                        </Form.Item>
                        <Form.Item name="projectDetail" label="项目说明">
                            <div>{ZjData?.projectDetail}</div>
                        </Form.Item>
                        <Form.Item name="orgCode" label="主体代码">
                            <div>{ZjData?.orgCode}</div>
                        </Form.Item>
                        <Form.Item name="productCompanyName" label="生产方公司名称">
                            <div>{ZjData?.productCompanyName}</div>
                        </Form.Item>
                        <Form.Item name="productCompanyName" label="生产方负责人">
                            <div>{ZjData?.productCompanyMaster}</div>
                        </Form.Item>
                        <Form.Item name="productCompanyName" label="生产方负责人联系电话">
                            <div>{ZjData?.productCompanyContactPhone}</div>
                        </Form.Item>
                        <Form.Item name="address" label="生产方地址">
                            <div>{ZjData?.address}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='生产方定位'>
                            {(ZjData?.demandLat && ZjData?.demandLng) ? (
                                <div style={{width: '20vw', height: "20vh"}}>
                                    <Map plugins={['ToolBar']} center={{longitude: ZjData?.demandLng, latitude: ZjData?.demandLat}} zoom={17}>
                                        <Marker position={{longitude: ZjData?.demandLng, latitude: ZjData?.demandLat}}/>
                                    </Map>
                                </div>
                            ) : (<div>
                                <Tooltip title={'暂无定位'}>
                                    <img src={noneIcon} alt="" width={40} height={20}/>
                                </Tooltip>
                            </div>)}
                        </Form.Item>
                        <Form.Item name="receiveCompanyName" label="接收方公司名称">
                            <div>{ZjData?.receiveCompanyName}</div>
                        </Form.Item>
                        <Form.Item name="receiveCompanyName" label="接收方负责人">
                            <div>{ZjData?.receiveCompanyMaster}</div>
                        </Form.Item>
                        <Form.Item name="receiveCompanyName" label="接收方负责人联系电话">
                            <div>{ZjData?.receiveCompanyContactPhone}</div>
                        </Form.Item>
                        <Form.Item name="receiveAddress" label="接收方地址">
                            <div>{ZjData?.receiveAddress}</div>
                        </Form.Item>
                            <Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='接收方定位'>
                                {(ZjData?.handleLat && ZjData?.handleLng) ? (
                                    <div style={{width: '20vw', height: "20vh"}}>
                                        <Map plugins={['ToolBar']} center={{longitude: ZjData?.handleLng, latitude: ZjData?.handleLat}} zoom={17}>
                                            <Marker position={{longitude: ZjData?.handleLng, latitude: ZjData?.handleLat}}/>
                                        </Map>
                                    </div>
                                ) : (<div>
                                    <Tooltip title={'暂无定位'}>
                                        <img src={noneIcon} alt="" width={40} height={20}/>
                                    </Tooltip>
                                </div>)}
                            </Form.Item>

                        <Form.Item name="projectAuditState" label="项目审核状态">
                            <div>{ZjData?.projectAuditState}</div>
                        </Form.Item>
                    </Form>
                </Modal>


                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>
                <Modal open={imgModalVisible} onCancel={() => {
                    handleCloseImgModal();
                    setLoading(false)
                }} title={'图片'} maskClosable={false} footer={<Button>关闭</Button>} destroyOnClose>

                    <div style={{textAlign:"center" }}> <Image src={codeUrl} height={400}></Image></div>
                </Modal>
            </div>
        );
    }
;

export default Demand;